import React from "react";

// Customizable Area Start
import EmailAccountRegistrationSellerController, {
  Props,
  configJSON
} from "./EmailAccountRegistrationSellerController.web"
import {
  createTheme,
} from "@mui/material/styles";
import { eyeOpenIcon, eyeCloseIcon, uploadIcon, rightArrowIcon } from "./assets";
import {Box, Grid, InputLabel, TextField, Button, Typography, Divider} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#ffffff",
    },
  },
});
// Customizable Area End

export default class EmailAccountRegistrationSeller extends EmailAccountRegistrationSellerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderInputField(
    label: string, 
    placeholder: string, 
    value: string | undefined, 
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void, 
    name: string, 
    dataTestId: string,
    error: string
  ) {
    return (
      <Grid item lg={6} md={4} sm={4} xs={12}>
        <InputLabel className="avenirBlack">{label}</InputLabel>
        <TextField
          sx={{
            "& fieldset": { border: 'none' },
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                border: '1px solid #53503F',
              },
            },
          }}
          fullWidth
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          style={styles.inputField}
          data-testid={dataTestId}
        />
        {error ? (
          <p style={styles.error} id='emailError' className='avenirBlack'>
            {error}
          </p>
          ) : null
        }
      </Grid>
    );
  }

  renderPasswordField(
    name: string,
    label: string, 
    placeholder: string, 
    value: string, 
    dataTestId: string, 
    eyeState: boolean, 
    handleEyeClick: () => void, 
    eyeIconTestId: string,
    handleChange: any,
    error: string
  ) {
    return (
      <Grid item lg={6} md={4} sm={4} xs={12}>
        <InputLabel className="avenirBlack">{label}</InputLabel>
        <Box sx={styles.passwordField}>
          <TextField
            sx={{
              "& fieldset": { border: 'none' },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  border: '1px solid #53503F',
                },
              },
            }}
            name={name}
            fullWidth
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            style={styles.inputField}
            type={eyeState ? 'text' : 'password'}
            data-testid={dataTestId}
          />
          <Box
            data-testid={eyeIconTestId}
            onClick={handleEyeClick}
            sx={error ? styles.eyeIconWithError : styles.eyeIconMainWithOutError}
          >
            <img src={eyeState ? eyeOpenIcon : eyeCloseIcon} alt="eyeIcon" />
          </Box>
          {error ? (
            <p style={styles.error} id='emailError' className='avenirBlack'>
              {error}
            </p>
            ) : null
          }
        </Box>
      </Grid>
    );
  }
  // Customizable Area End

  render() {
     // Customizable Area Start
    const { selectedFiles, errorMessage, previews, firstUserName, lastUserName, workEmail, number, password, confirmPassword,location } = this.state;
      // Customizable Area End
    return (
    // Customizable Area Start
      //Merge Engine DefaultContainer
      <Box sx={styles.root}>
        {this.state.formNumber === 1 ? 
        <Box style={styles.formMain}>
          <form>
            <Grid container spacing={2}>
            {this.renderInputField(
              configJSON.labelFirstName,
              configJSON.labelFirstName,
              this.state.firstUserName,
              this.handleFirstUserName,
              'firstName',
              'firstNameTestId',
              this.state.firstUserNameError
            )}
            {this.renderInputField(
              configJSON.lastName,
              configJSON.lastName,
              this.state.lastUserName,
              this.handleLastUserName,
              'lastName',
              'lastNameTestId',
              this.state.lastUserNameError
            )}
            {this.renderInputField(
              configJSON.workEmailLabel,
              configJSON.workEmailLabel,
              this.state.workEmail,
              this.handleWorkEmail,
              'workEmail',
              'workEmailTestId',
              this.state.workEmailError
            )}
            {this.renderInputField(
              configJSON.phoneNumber,
              configJSON.phoneNumber,
              this.state.number,
              this.handlePhoneNumber,
              'number',
              "numberTestId",
              this.state.numberError
            )}
            {this.renderPasswordField(
              'password',
              configJSON.labelPassword,            
              configJSON.labelPassword,            
              this.state.password,                
              'passwordTestId',                   
              this.state.eyeIconPassword,         
              this.handlePasswordEye,             
              'eyeIconPasswordTestId',
              this.handlePassword,
              this.state.passwordError                        
            )}
            {this.renderPasswordField(
              'confirmPassword',
              configJSON.confirmPassword,          
              configJSON.confirmPassword,          
              this.state.confirmPassword,         
              'confirmPasswordTestId',            
              this.state.eyeIconConfirm,          
              this.handleConfirmPasswordEye,             
              'eyeIconConfirmPassTestId',
              this.handleConfirmPassword,
              this.state.confirmPasswordError       
            )}
            <Grid item lg={6} md={4} sm={4} xs={12}>
              <InputLabel className="avenirBlack">{configJSON.locationText}</InputLabel>
              <FormControl fullWidth sx={styles.selectField}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.location}
                onChange={this.handleLocationChange}
                displayEmpty
                sx={styles.locationDropdown}
                data-testid="locationTestId"
                name="location"
              >
                <MenuItem value="">
                  <em>Select</em>
                </MenuItem>
                {Array.isArray(this.state.locationData) && this.state.locationData.map((locate) => 
                  <MenuItem value={locate.attributes.id}>{locate.attributes.name}</MenuItem>
                )}
              </Select>
            </FormControl>
            {this.state.locationError ? (
              <p style={styles.error} id='locationError1' className='avenirBlack'>
                {this.state.locationError}
              </p>
              ) : null
            }
            </Grid>
            </Grid>  
            <Grid item lg={6} md={4} sm={4} xs={12}>
              {this.state.backendError ? (
                <p style={styles.error} id='emailError' className='avenirBlack'>
                  {this.state.backendError }
                </p>
                ) : null
              }
            </Grid>
            <Box style={styles.createAccount}>
              <Box style={styles.buttonStyle}>
                <Button variant="contained" data-testid="cancelBtnTestId" onClick={this.handleCancelBtn} sx={styles.btnCancel}>Cancel</Button>
                <Button 
                disabled= {!firstUserName || !lastUserName || !workEmail || !number || !password || !confirmPassword || !location}
                data-testid='nextTestId' onClick={this.handleNextBtn} variant="contained" sx={styles.btn}>
                  Next
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
        :
        <Box style={styles.formMain}>
          <form>
            <Grid container spacing={2}>
            {this.renderInputField(
              configJSON.addressLine1Label,
              configJSON.addressPlaceholder,
              this.state.addresssLine1,
              this.handleAddresssLine1,
              'addressLine1',
              'addressLine1TestId',
              this.state.addresssLine1Error
            )}
            {this.renderInputField(
              configJSON.addressLine2Label,
              configJSON.addressPlaceholder,
              this.state.addresssLine2,
              this.handleAddresssLine2,
              'addressLine2',
              'addressLine2TestId',
              this.state.addresssLine2Error
            )}
            <Grid item lg={6} md={4} sm={4} xs={12}>
              <InputLabel className="avenirBlack">{configJSON.cityLabel}</InputLabel>
              <FormControl fullWidth sx={styles.selectField}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.city}
                  onChange={this.handleCityChange}
                  displayEmpty
                  sx={styles.locationDropdown}
                  data-testid="cityTestId"
                  name="city"
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  {Array.isArray(this.state.cityData) && this.state.cityData.map((city) => 
                    <MenuItem value={city}>{city}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            {this.renderInputField(
              configJSON.addressLine2Label,
              configJSON.addressPlaceholder,
              this.state.comRegistrationNum,
              this.handleAddresssLine2,
              'addressLine2',
              'addressLine2TestId',
              this.state.addresssLine2Error
            )}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box
                border="1px dashed grey"
                p={2}
                onDrop={this.handleDrop}
                onDragOver={this.handleDragOver}
                textAlign="center"
                sx={{
                  margin: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Box><img src={uploadIcon} alt="uploadIcon"/></Box> 
                <p style={styles.dragText}>Drag & Drop here</p>
                <Divider style={{ width: '50%', margin: 'auto',padding: '10px' }}>OR</Divider>
                <Box sx={{width: '100%'}}>
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      backgroundColor: '#6f5e4e',
                      color: '#ffffff',
                      width: '300px',
                      fontWeight: 400,
                      '&:hover': { backgroundColor: '#594d40' },
                    }}
                  >
                    Browse files 
                    <img src={rightArrowIcon} alt='rightArrow'/>
                    <input
                      type="file"
                      hidden
                      multiple
                      onChange={this.handleFileChange}
                      accept=".jpg, .png, .pdf"
                    />
                  </Button>
                </Box>
                <Typography sx={styles.imageSupport}>
                  Only supports .jpg, .png, and .pdf files. File size should be more than 10 KB and not more than 5 MB.
                </Typography>
              </Box>

              {selectedFiles.length > 0 && (
                <Box mt={2} sx={{ maxWidth: 500, margin: 'auto' }}>
                  <Typography variant="subtitle1">Selected files:</Typography>
                  <ul>
                    {selectedFiles.map((file: any, index: any) => (
                      <li key={index}>{file.name}</li>
                    ))}
                  </ul>
                </Box>
              )}

              {previews.length > 0 && (
                <Box mt={2} sx={{ maxWidth: 500, margin: 'auto' }}>
                  <Typography variant="subtitle1">File Previews:</Typography>
                  <Box display="flex" flexWrap="wrap">
                    {previews.map((preview: any, index: any) => {
                      const fileType = selectedFiles[index].type;
                      return (
                        <Box key={index} p={1}>
                          {fileType === 'application/pdf' ? (
                            <embed src={preview} type="application/pdf" width="200px" height="200px" />
                          ) : (
                            <img src={preview} alt="preview" width="200px" height="200px" />
                          )}
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              )}

              {errorMessage && (
                <Typography color="error" mt={2} sx={{ maxWidth: 500, margin: 'auto' }}>
                  {errorMessage}
                </Typography>
              )}
            </Grid>
            </Grid>
            <Box style={styles.createAccount}>
              <Button data-testid='submitTestId' onClick={this.handleSubmit} variant="contained" sx={styles.btn}>
                Submit
              </Button>
            </Box>
          </form>
        </Box>
        }
      </Box>
      //Merge Engine End DefaultContainer
    // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  imageSupport: {
    fontSize: '12px',
    margin: '10px 0px'
  },
  dragText: {
    fontSize: '14px'
  },
  dropzone: {
    padding: '10px',
    border: '1px dashed #000000'
  },
  formMain: {
    width: '100%'
  },
  inputField: {
    marginTop: '5px',
    marginBottom: '10px',
    backgroundColor: '#ffffff',
    border: 'none',
  },
  passwordField: {
    position: 'relative'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    backgroundColor: '#EFEEE9', 
    borderRadius: 8,
  },
  logo: {
    padding: '10px'
  },
  fieldLabel: {
    fontFamily: "Avenir",
    fontSize: '10px',
    fontWeight: '800',
    lineHeight: '24px',
    textAlign: "left",
    color: '#53503F'
  }, 
  remember: {
    display: 'flex',
  },
  eyeIconMainWithOutError: {
    position: 'absolute',
    right: '15px',
    bottom: '40%'
  },
  eyeIconWithError: {
    position: 'absolute',
    right: '15px',
    bottom: '56%'
  },
  rememberMain: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  createAccount: {
    margin: 'auto',
    width: '100%'
  },
  btn: {
    backgroundColor: '#53503F',
    color: '#ffffff',
    marginTop: '10px',
    paddingTop: '15px',
    paddingBottom: '15px',
    textTransform: 'none',
    width: '100%',
    '&:hover': {
      backgroundColor: '#403D2F',
      boxShadow: 'none',
    },
    '.MuiButtonBase-root-MuiButton-root': {
      padding: '100px'
    }
  },
  buttonStyle: {
    display: 'flex',
    justifyContent: 'spaceBetween',
    padding: '0px 70px',
    gap: '100px'
  },
  btnCancel: {
    backgroundColor: 'transparent',
    color: '#53503F',
    marginTop: '10px',
    paddingTop: '15px',
    paddingBottom: '15px',
    textTransform: 'none',
    width: '100%',
    '&:hover': {
      backgroundColor: '#403D2F',
      boxShadow: 'none',
      color: '#ffffff'
    },
    '.MuiButtonBase-root-MuiButton-root': {
      padding: '100px'
    }
  },
  welcomeText: {
    fontFamily: "Avenir",
    fontSize: '20px',
    fontWeight: '400',
  },
  error: {
    margin: '0px',
    fontSize: "12px",
    fontWeight: 800,
    color: '#ff0000',
    paddingBottom: '15px'
  },
  selectField: {
    marginTop: '5px',
    marginBottom: '10px',
    backgroundColor: '#f0f0f0', 
    '&:focus': {
        backgroundColor: '#f0f0f0',
    },
  },
  radioField: {
    marginTop: '5px',
    marginBottom: '10px',
    backgroundColor: '#f0f0f0', 
    '&:focus': {
        backgroundColor: '#f0f0f0',
    },
  },
  toggleMain: {
    gap: '15px',
    marginTop: '5px',
    marginBottom: '10px'
  },
  toggleBtn: {
    borderRadius: '8px !important',
    border: '2px solid #53503F !important', 
    color: '#53503F', 
    '&.Mui-selected': {
      backgroundColor: '#53503F', 
      color: '#ffffff',
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#53503F',
    },
  },
  locationDropdown: {
      backgroundColor: '#ffffff',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
  },
  termCondition: {
    display: 'flex',
    color: '#53503F'
  },
  termBold: {
    fontWeight: 800,
    textDecoration: 'underline'
  },
  uploadBoxMain: {
    border: '2px solid #53503F',
    borderStyle: 'dashed'
  }
};
// Customizable Area End