import React, { useState, useEffect } from "react";
import {
  IconButton,
  Box,
  TextField,
  Button,
  Grid,
  Typography,
  Modal,
  styled
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "none",
  outline: "none", 
  "&:focus-visible": {
    outline: "none",
  },
});

const ModalBox = styled(Box)({
  position: "relative",
  width: "460px",
  padding: "20px",
  borderRadius: "20px",
  textAlign: "center",
  backgroundColor: "#EFEEE9",
});

const ModalTitle = styled(Typography)({
  marginTop: "30px",
  fontFamily: "'Avenir-Black','Poppins-Light', sans-serif",
  fontSize: "24px",
  fontWeight: 800,
  lineHeight: "37.49px",
  textAlign: "center",
  color: "#53503F",
});

const OtpContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  margin: "auto",
  marginBottom: "15px",
  width: "360px",
  marginTop: "50px",
});

const OtpTextField = styled(TextField)(({ error }: any) => ({
  width: "56px",
  height: "56px",
  borderRadius: "8px",
  backgroundColor: "white",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: error ? "1px solid #DC2626" : "none",
    },
    "&:hover fieldset": {
      border: error ? "1px solid #DC2626" : "none",
    },
    "&.Mui-focused fieldset": {
      border: error ? "1px solid #DC2626" : "1px solid #53503F",
    },
  },
}));

const TimerContainer = styled(Typography)({
  marginBottom: "35px !important",
  boxSizing:"border-box",
  marginTop: "45px !important",
  width: "9rem",
  height: "44px",
  padding: "10px 24.5px 10px 20.5px",
  borderRadius: "8px",
  border: "0.5px solid #53503F",
  backgroundColor: "white",
  color: "#53503F",
  fontFamily: "'Avenir-Black','Poppins-Light', sans-serif",
  fontSize: "16px",
  fontWeight: 800,
  lineHeight: "24px",
  margin: "auto",
});

const FooterButton = styled("button")({
  width: "360px",
  height: "56px",
  padding: "16px 0px",
  borderRadius: "8px",
  color: "white",
  border: "none",
  backgroundColor: "#53503F",
  fontFamily: "'Avenir-Black','Poppins-Light', sans-serif",
  fontSize: "18px",
  fontWeight: 800,
  cursor: "pointer",
});

const OTPModal = ({ handleSubmitOtp,openOtpModal,handleCloseOtpModal,otpErr,handleResendOtp }: any) => {
  const [otp, setOtp] = useState(Array(4).fill(""));
  const [timer, setTimer] = useState(180); // 180 seconds = 3 minutes
  const [errorMessage, setErrorMessage] = useState(""); // Error message state

  // Timer countdown and modal close when time's up
  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown);
    } else {
      setErrorMessage(" ");
    }
  }, [timer,openOtpModal]);

  // Handle OTP input change
  const handleChange = (e: any, index: number) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === "") {
      let newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setErrorMessage(""); // Clear error message on input change

      // Move to next field only if there's input and it's not the last input field
      if (value !== "" && index < otp.length - 1) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput) {
          nextInput.focus(); // Focus only if the element is found
        }
      }
    }
  };

  // Handle backspace navigation
  const handleBackspace = (e: any, index: number) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      const prevInput = document.getElementById(`otp-input-${index - 1}`);
      if (prevInput) {
        prevInput.focus(); // Focus only if the element is found
      }
    }
  };

  // Handle form submission
  const handleSubmit = () => {
    if (otp.includes("")) {
      setErrorMessage("Invalid OTP"); 
    }
     else {
      const enteredOtp=otp.join("")
      handleSubmitOtp(enteredOtp)
      setErrorMessage("")
    }
  };

  // Format timer
  const formatTime = (seconds: any) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")} Mins`;
  };

  return (
    <StyledModal
      open={openOtpModal}
      onClose={handleCloseOtpModal}
    >
      <ModalBox>
        <ModalTitle>
          OTP Verification
          <IconButton
            aria-label="close"
            onClick={handleCloseOtpModal}
            sx={{
              position: "absolute",
              right: 20,
              top: 20,
              color: "#53503F",
              height:"24px",
              width:"24px"
            }}
          >
            <CloseIcon />
          </IconButton>
        </ModalTitle>
        <OtpContainer>
          {otp.map((digit, index) => (
            <OtpTextField
              key={index}
              id={`otp-input-${index}`}
              value={digit}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleBackspace(e, index)}
              inputProps={{
                maxLength: 1,
                style: {
                  textAlign: "center",
                  fontSize: "1.5rem",
                  padding: "10px",
                },
              }}
              variant="outlined"
              error={errorMessage !== ""}
            />
          ))}
        </OtpContainer>
        {errorMessage && (
          <Typography
            variant="body2"
            sx={{ marginBottom: "15px", color: "#DC2626" }}
          >
            {errorMessage}
          </Typography>
        )}
        <TimerContainer>{formatTime(timer)}</TimerContainer>
        <Grid md={12} lg={12} sm={12} sx={{ display: "flex", justifyContent: "center" }}>
          <span style={{
            marginBottom: "20px",
            fontFamily: "'Avenir-Black','Poppins-Light', sans-serif",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "22px",
            color: "#53503F",
            width: "100%",
            textAlign: "center"
          }}>
            Not received OTP?{" "}
            <span onClick={()=>{
                setTimer(180)
                handleResendOtp()}} style={{
              cursor: "pointer",
              fontWeight: 800,
            }}>
              Resend OTP
            </span>
          </span>
        </Grid>
        <FooterButton onClick={handleSubmit} style={{ marginTop: "35px", marginBottom: "36px" }}>
          Submit
        </FooterButton>
      </ModalBox>
    </StyledModal>
  );
};

export default OTPModal;
