import React from "react";
import {
  Box,
  TextField,
  Grid,
  Typography,
  Modal,
  styled,
  InputLabel
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const eyeIcon=require("../socialmediaicons/image_.png").default
const eyeCloseIcon=require("../socialmediaicons/image_visibility_off.png").default



const FooterButton = styled("button")({
  width: "360px",
  height: "56px",
  padding: "16px 0px",
  borderRadius: "8px",
  color: "white",
  border: "none",
  backgroundColor: "#53503F",
  fontFamily: "'Avenir-Black','Poppins-Light', sans-serif",
  fontSize: "18px",
  fontWeight: 800,
  cursor: "pointer",
});
const styles = {
    logo: {
      padding: "10px",
    },
    formMain: {
      width: "100%",
    },
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "16px",
      backgroundColor: "#EFEEE9",
      borderRadius: 8,
    },
    inputField: {
      marginTop: "5px",
      marginBottom: "10px",
      backgroundColor: "#fff",
      border: "none",
    },
    passwordField: {
      position: "relative",
    },
    eyeIconMainWithOutError: {
      position: "absolute",
      right: "15px",
      bottom: "40%",
    },
    remember: {
      display: "flex",
    },
    rememberMain: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    welcome: {
      margin: "10px",
    },
    eyeIconWithError: {
      position: "absolute",
      right: "15px",
      bottom: "56%",
    },
    fieldLabel: {
      fontFamily: "'Avenir-Black','Poppins-Light', sans-serif",
      fontSize: "10px",
      fontWeight: "800",
      lineHeight: "24px",
      textAlign: "left",
      color: "#53503F",
    },
    welcomeText: {
      fontFamily: "'Avenir-Black','Poppins-Light', sans-serif",
      fontSize: "20px",
      fontWeight: "400",
      color: "#53503F",
    },
    btn: {
      backgroundColor: "#53503F",
      color: "#fff",
      marginTop: "10px",
      paddingTop: "10px",
      paddingBottom: "10px",
      textTransform: "none",
      width: "100%",
      "&:hover": {
        backgroundColor: "#403D2F",
        boxShadow: "none",
      },
    },
    error: {
      margin: "0px",
      fontSize: "12px",
      fontWeight: 800,
      color: "red",
      paddingBottom: "15px",
    },
    inpLable: {
      fontFamily: "'Avenir-Black','Poppins-Light', sans-serif",
      fontSize: "16px",
      fontWeight: 800,
      lineHeight: "24px",
      textAlign: "left",
    },
  };
interface Iprops {
    resetOtpModal:boolean,
    handleClose:any,
    newPassErr:string,
    newPassword:string,
    showNewPass:any,
    handleNewPasswordShowHide:any,
    newConfErr:string,
    newConfPassword:string,
    showConfPass:any,
    handleNewConfPasswordShowHide:any,
    handleSubmit:any,
    handlePassword:any
}
const ResetPassModal = (props: Iprops) => {
   const {resetOtpModal,handleClose,newPassErr,newPassword,showNewPass,handleNewPasswordShowHide
        ,newConfErr,newConfPassword,showConfPass,handleNewConfPasswordShowHide,handleSubmit,handlePassword
     }=props
  return (
    <Modal
        open={resetOtpModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Background darkening
            backdropFilter: "blur(5px)", // Blur effect
          },
        }}
      >
        <Box
          sx={{
            boxSizing: "border-box",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#EFEEE9",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
            maxWidth: "460px",
            width: "460px",
            outline: "none",
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon
            onClick={handleClose}
              style={{color:"#53503F", height: "24px", width: "24px", float: "right" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              style={{
                fontFamily: "'Avenir-Black','Poppins-Light', sans-serif",
                fontSize: "28px",
                fontWeight: 800,
                lineHeight: "32px",
                letterSpacing: "-0.11999999731779099px",
                textAlign: "center",
                color: "#53503F",
              }}
              component="h2"
            >
              Reset Password
            </Typography>
       <Grid sx={{marginTop:"35px"}} md={12} lg={12} sm={12}>
              <form>
            <Grid item xs={12} sm={12} lg={12}>
              <InputLabel sx={styles.inpLable} className="avenirBlack">{"New Password"}</InputLabel>
              <Box sx={styles.passwordField}>
              <TextField
              sx={{
                borderRadius:"8px",
                  "& fieldset": { border: 
                    newPassErr ? '1px solid red':'none' },
                  '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                          border: '1px solid #53503F',
                      },
                  },
              }}
              fullWidth
              name='password'
              placeholder={"Your password"}
              value={newPassword}
              onChange={(e:any)=>handlePassword(e,"newPassword")}
              style={styles.inputField}
              type={showNewPass ? 'text' : 'password'}
              data-testid='passwordTestId'
              />
              {newPassErr ? (
              <p style={styles.error} id='emailError' className='avenirBlack'>
                {newPassErr} 
              </p>
              ) : null
              }
                <Box data-testid="eyeIconTestId" onClick={handleNewPasswordShowHide} sx={newPassErr ? styles.eyeIconWithError : styles.eyeIconMainWithOutError}>
                  {showNewPass ?
                  <img src={eyeIcon} alt="eyeIcon"/>:
                  <img src={eyeCloseIcon} alt="eyeIcon"/>
                  }
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <InputLabel sx={styles.inpLable} className="avenirBlack">{"Confirm Password"}</InputLabel>
              <Box sx={styles.passwordField}>
              <TextField
              sx={{
                  borderRadius:"8px",
                  "& fieldset": { border: 
                    newConfErr ? '1px solid red':'none' },
                  '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                          border: '1px solid #53503F',
                      },
                  },
              }}
              fullWidth
              name='password'
              placeholder={"Your password"}
              value={newConfPassword}
              onChange={(e:any)=>handlePassword(e,"newConfPassword")}
              style={styles.inputField}
              type={showConfPass ? 'text' : 'password'}
              data-testid='passwordTestId'
              />
              {newConfErr ? (
              <p style={styles.error} id='emailError' className='avenirBlack'>
                {newConfErr}
              </p>
              ) : null
              }
                <Box data-testid="eyeIconTestId" onClick={handleNewConfPasswordShowHide} sx={newConfErr ? styles.eyeIconWithError : styles.eyeIconMainWithOutError}>
                  {showConfPass ?
                  <img src={eyeIcon} alt="eyeIcon"/>:
                  <img src={eyeCloseIcon} alt="eyeIcon"/>
                  }
                </Box>
              </Box>
            </Grid>
              <FooterButton
              style={{ marginTop: "35px", marginBottom: "15px" }}
              onClick={(e:any) => handleSubmit(e)}
            >
              Update
            </FooterButton>

              </form>
       </Grid>           
          </div>
        </Box>
      </Modal>
  );
};

export default ResetPassModal;


